export default [
  // {
  //   path: '/',
  //   name: 'Home',
  //   icon: 'mdi-run-fast',
  //   component: () => import('@/views/Home.vue'),
  // },
  {
    path: '/',
    name: 'Race Info',
    icon: 'mdi-run-fast',
    component: () => import('@/views/RaceInfo.vue'),
  },
  {
    path: '/register',
    name: 'Registration',
    icon: 'mdi-file-document-edit-outline',
    component: () => import('@/views/Registration.vue'),
  },
  // {
  //   path: '/madlibresults',
  //   name: 'madlibresults',
  //   icon: 'mdi-file-document-edit-outline',
  //   component: () => import('@/views/MadLibReview.vue'),
  // },
];
