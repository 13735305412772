<template>
  <v-navigation-drawer
    v-model="drawerVisible"
    app
    clipped
    color="secondary"
  >
    <v-list>
      <v-list-item
        v-for="route in routes"
        :key="route.name"
        :to="route.path"
        :href="route.link"
        :target="route.link ? '_blank' : null"
        link
        class="white--text"
      >
        <v-list-item-icon>
          <component
            :is="route.icon"
            v-if="route.icon && !route.icon.includes('mdi')"
          />
          <v-icon
            v-else
            v-text="route.icon"
          />
        </v-list-item-icon>
        <v-list-item-content
          v-text="route.name"
        />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import routes from '@/constants/routes';

export default {
  name: 'NavigationDrawer',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    routes,
  }),
  computed: {
    drawerVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
