<template>
  <v-app>
    <navigation-drawer
      v-model="showDrawer"
    />

    <app-bar
      @app-bar-icon-clicked="showDrawer = !showDrawer"
    />

    <v-main
      class="main"
    >
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <my-footer />
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer.vue';
import AppBar from '@/components/AppBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    'my-footer': Footer,
    NavigationDrawer,
  },
  data: () => ({
    showDrawer: false,
  }),
};
</script>

<style lang="scss">
body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #795548;
  .main{
    background-color: #795548;
  }
  .v-application {
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
  }
}

</style>
