<template>
  <v-footer
    app
    class="footer white--text"
    color="primary"
  >
    <span>&copy; {{ copyrightYear }} </span>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    copyrightYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
