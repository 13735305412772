<template>
  <v-app-bar
    app
    clipped-left
    color="primary"
    class="white--text"
  >
    <v-app-bar-nav-icon
      class="white--text"
      @click="$emit('app-bar-icon-clicked')"
    />

    <v-toolbar-title
      class="toolbar-title"
    >
      3rd Annual TrotTom.com 8k Race 🦃🏃‍♂️🏃‍♀️🥈
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar',
};
</script>

<style lang="scss" scoped>

</style>
